import {
    all,
    call,
    put,
    select,
    takeEvery,
    takeLatest
} from 'redux-saga/effects';
import { ApiResponse } from '../../api/apiInterfaces';
import { selectUserName, selectUserOrganizationId } from '../login/reducer';
import { ActionPayload } from '../types';
import {
    addGroupResponse,
    addPropertyResponse,
    ADD_PROPERTY,
    ADD_PROPERTY_GROUP,
    deletePropertyGroupResponse,
    deletePropertyResponse,
    DELETE_PROPERTY,
    DELETE_PROPERTY_GROUP,
    getPropertyListResponse,
    GET_PROPERTY_DETAILS,
    propertyDetailsResponse,
    propertyTypesResponse,
    PROPERTY_LIST,
    PROPERTY_TYPES,
    registerPropertyResponse,
    REGISTER_PROPERTY,
    DUPLICATE_PROPERTY,
    duplicatePropertyResponse,
    MOVE_PROPERTY,
    movePropertyResponse
} from './action';
import {
    AddGroupPayload,
    AddPropertyPayload,
    DeletePropertyGroupPayload,
    DuplicatePropertyPayload,
    MovePropertyPayload,
    PropertiesUnderPropertyGroupRes,
    PropertyGroupResponse,
    PropertyIdPayload,
    RegisterPropertyPayload
} from './actionPayload';
import {
    addGroup,
    addProperty,
    deleteProperty,
    fetchAllProperties,
    fetchPropertyDetails,
    getPropertyTypes,
    propertyGroupDelete,
    registerPropertyHelper,
    moveProperty,
    duplicatePropertyHelper
} from './helper';
import { PropertyDetails, PropertyGroupInterface, PropertyType } from './model';
//=============================================================
//               Get Property List
//=============================================================

function* getPropertyListWorker() {
    try {
        const propertiesResponse: ApiResponse<PropertyGroupInterface[]> =
            yield call(fetchAllProperties);
        yield put(
            getPropertyListResponse(
                false,
                propertiesResponse.message,
                propertiesResponse.response_body
            )
        );
    } catch (error: any) {
        yield put(getPropertyListResponse(true, error.message));
    }
}

// ==================================================================
//                Add Group
// ==================================================================

function* addGroupWorker(data: ActionPayload<AddGroupPayload>) {
    try {
        // const store = yield select();
        const orgId: string = yield select(selectUserOrganizationId);
        const newPayload = {
            groupName: data.payload.groupName,
            organization_id: data?.payload?.organizationId
                ? data?.payload?.organizationId
                : orgId
        };
        const response: ApiResponse<PropertyGroupResponse> = yield call(
            addGroup,
            newPayload
        );

        yield put(
            addGroupResponse(false, response.message, response.response_body)
        );
    } catch (error: any) {
        yield put(addGroupResponse(true, error.message));
    }
}
//================================================================
//                  Add Property
//================================================================

function* addPropertyWorker(data: ActionPayload<AddPropertyPayload>) {
    try {
        const userName: string = yield select(selectUserName);
        const newData = { ...data };
        newData.payload.userName = userName;
        const response: ApiResponse<PropertiesUnderPropertyGroupRes> =
            yield call(addProperty, newData);
        yield put(
            addPropertyResponse(false, response.message, response.response_body)
        );
    } catch (error: any) {
        yield put(addPropertyResponse(true, error.message));
    }
}

//===================================================================
//                       Register Property
//===================================================================

function* registerPropertyWorker(data: ActionPayload<RegisterPropertyPayload>) {
    try {
        const userName: string = yield select(selectUserName);
        const response: ApiResponse<string> = yield call(
            registerPropertyHelper,
            data.payload,
            userName
        );
        yield put(registerPropertyResponse(response, false));
    } catch (err) {
        const e: any = err;
        const result = e.message;
        yield put(registerPropertyResponse(result, true, result));
    }
}

//=============================================================================================
//                          Smart Box Details
//=============================================================================================

function* getSmartBoxDetailsWorker(data: ActionPayload<string>) {
    try {
        const response: ApiResponse<PropertyDetails> = yield call(
            fetchPropertyDetails,
            data
        );
        yield put(propertyDetailsResponse(response.response_body, false));
    } catch (err) {
        const e: any = err;
        const result: any = e.message;
        yield put(propertyDetailsResponse(result, true));
    }
}

//=============================================================================
//                   Delete Property
//=============================================================================

function* deletePropertyWorker(payload: ActionPayload<PropertyIdPayload>) {
    try {
        const { propertyId } = payload?.payload;
        const deletePropertyRes: ApiResponse<undefined> = yield call(
            deleteProperty,
            propertyId
        );
        yield put(deletePropertyResponse(false, deletePropertyRes.message));
    } catch (err: any) {
        yield put(deletePropertyResponse(true, err.message));
    }
}

function* deletePropertyGroupWorker(
    data: ActionPayload<DeletePropertyGroupPayload>
) {
    try {
        const { propertyGroupId } = data?.payload;
        const propertyGroupDeleteRes: ApiResponse<undefined> = yield call(
            propertyGroupDelete,
            propertyGroupId
        );
        yield put(
            deletePropertyGroupResponse(false, propertyGroupDeleteRes.message)
        );
    } catch (err: any) {
        yield put(deletePropertyGroupResponse(true, err.message));
    }
}

//=============================================================================
//                   Property Types
//=============================================================================

function* getPropertyTypesWorker() {
    try {
        const propertyTypesRes: ApiResponse<PropertyType[]> = yield call(
            getPropertyTypes
        );
        yield put(
            propertyTypesResponse(false, '', propertyTypesRes.response_body)
        );
    } catch (err: any) {
        console.log('propertyTypes call failed');
        yield put(propertyTypesResponse(true, err.message));
    }
}

//=============================================================================
//              Move Property
//=============================================================================

function* movePropertyWorker(data: ActionPayload<MovePropertyPayload>) {
    try {
        const movePropertyTypesRes: ApiResponse<MovePropertyPayload> =
            yield call(moveProperty, data);
        yield put(movePropertyResponse(false, movePropertyTypesRes.message));
    } catch (err: any) {
        yield put(movePropertyResponse(true, err.message));
    }
}

//=============================================================================
//              Duplicate Property
//=============================================================================

function* duplicatePropertyWorker(
    data: ActionPayload<DuplicatePropertyPayload>
) {
    try {
        const duplicatePropertyTypesRes: ApiResponse<DuplicatePropertyPayload> =
            yield call(duplicatePropertyHelper, data);
        yield put(
            duplicatePropertyResponse(false, duplicatePropertyTypesRes.message)
        );
    } catch (err: any) {
        yield put(duplicatePropertyResponse(true, err.message));
    }
}

export function* getPropertyListWatcher() {
    yield all([
        takeEvery(PROPERTY_LIST, getPropertyListWorker),
        takeLatest(ADD_PROPERTY_GROUP, addGroupWorker),
        takeLatest(ADD_PROPERTY, addPropertyWorker),
        takeLatest(REGISTER_PROPERTY, registerPropertyWorker),
        takeLatest(GET_PROPERTY_DETAILS, getSmartBoxDetailsWorker),
        takeLatest(DELETE_PROPERTY, deletePropertyWorker),
        takeLatest(DELETE_PROPERTY_GROUP, deletePropertyGroupWorker),
        takeLatest(PROPERTY_TYPES, getPropertyTypesWorker),
        takeLatest(MOVE_PROPERTY, movePropertyWorker),
        takeLatest(DUPLICATE_PROPERTY, duplicatePropertyWorker)
    ]);
}
