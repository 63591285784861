import { set } from 'lodash';
import { Dispatch, MiddlewareAPI } from 'redux';
import { isSuperRole } from '../utils/loginUtils';
import { CREATE_ASSETS, DELETE_ASSET, EDIT_ASSET } from './assets/action';
import {
    CreateAssetsRequest,
    DeleteAsset,
    EditAsset
} from './assets/actionPayload';
import { CREATE_ELEMENT, SAVE_ELEMENT, SAVE_ELEMENTS } from './element/action';
import {
    CreateAndSaveElement,
    SaveAllElementsPayload
} from './element/actionPayload';
import { DELETE_ELEMENT } from './elementsList/action';
import { DeleteElementPayload } from './elementsList/actionPayload';
import { UPDATE_EVOLVE2_FIELDS } from './evolve2/action';
import { Evolve2ConfigRequestBody } from './evolve2/model';
import { selectUserRole } from './login/reducer';
import {
    DELETE_PROPERTY,
    DUPLICATE_PROPERTY,
    MOVE_PROPERTY,
    REGISTER_PROPERTY
} from './propertylist/action';
import {
    DuplicatePropertyPayload,
    MovePropertyPayload,
    PropertyIdPayload,
    RegisterPropertyPayload
} from './propertylist/actionPayload';
import { selectCurrentPropertyDetails } from './propertylist/reducer';
import {
    saveActionToQueue,
    showGenericCommentModal,
    UPDATE_PROPERTY_MULTIPLE_FIELD_VALUE
} from './propertyValue/action';
import { UpdatePropertyFieldValuesBody } from './propertyValue/model';
import { ActionPayload } from './types';
import {
    CREATE_ZONE,
    DELETE_ZONE,
    EDIT_ZONE,
    UPDATE_ZONE_FIELDS
} from './zones/action';
import {
    CreateUpdateZone,
    DeleteZone,
    UpdateZoneFieldsPayload
} from './zones/actionPayload';

const ACTIONS_REQUIRING_COMMENTS = [
    UPDATE_ZONE_FIELDS,
    CREATE_ELEMENT,
    SAVE_ELEMENT,
    SAVE_ELEMENTS,
    DELETE_ELEMENT,
    UPDATE_PROPERTY_MULTIPLE_FIELD_VALUE,
    REGISTER_PROPERTY,
    CREATE_ZONE,
    EDIT_ZONE,
    DELETE_ZONE,
    UPDATE_EVOLVE2_FIELDS,
    EDIT_ASSET,
    DELETE_ASSET,
    CREATE_ASSETS,
    DELETE_PROPERTY,
    DUPLICATE_PROPERTY,
    MOVE_PROPERTY
];

function showCommentModalMiddleware(store: MiddlewareAPI) {
    return (next: Dispatch<any>) =>
        (
            action:
                | ActionPayload<SaveAllElementsPayload>
                | ActionPayload<UpdateZoneFieldsPayload>
                | ActionPayload<CreateAndSaveElement>
                | ActionPayload<UpdatePropertyFieldValuesBody>
                | ActionPayload<DeleteElementPayload>
                | ActionPayload<RegisterPropertyPayload>
                | ActionPayload<CreateUpdateZone>
                | ActionPayload<DeleteZone>
                | ActionPayload<Evolve2ConfigRequestBody>
                | ActionPayload<EditAsset>
                | ActionPayload<DeleteAsset>
                | ActionPayload<CreateAssetsRequest>
                | ActionPayload<PropertyIdPayload>
                | ActionPayload<DuplicatePropertyPayload>
                | ActionPayload<MovePropertyPayload>
        ) => {
            const state = store.getState();
            const role = selectUserRole(state);
            const isDishBusinessProperty =
                selectCurrentPropertyDetails(
                    state
                ).is_part_of_dish_business_organization;
            if (
                ACTIONS_REQUIRING_COMMENTS.includes(action.type) &&
                isSuperRole(role) &&
                !action?.meta?.superRoleCommentsProcessed &&
                !isDishBusinessProperty
            ) {
                store.dispatch(showGenericCommentModal());
                set(action, 'meta.superRoleCommentsProcessed', true);
                store.dispatch(saveActionToQueue(action));

                return null;
            }

            return next(action);
        };
}

export default showCommentModalMiddleware;
