import { API_URL } from '../../api/apiConstants';
import axios from '../../api/axios';
import { AddGroupHelperPayload } from './model';
import {
    AddPropertyPayload,
    DuplicatePropertyPayload,
    MovePropertyPayload,
    RegisterPropertyPayload
} from './actionPayload';
import { ActionPayload } from '../types';

export const addGroup = (data: AddGroupHelperPayload) => {
    const body = {
        organization_id: data.organization_id,
        name: data.groupName
    };
    return axios.post(API_URL.addGroup, body);
};

export const addProperty = (data: ActionPayload<AddPropertyPayload>) => {
    const body = {
        description: data.payload.propertyDescription,
        name: data.payload.property,
        property_group_id: data.payload.propertyGroupId,
        modified_by: data.payload.userName,
        modified_at: Date.now(),
        type: data.payload.propertyTypeId,
        zip_code: `${data.payload.zip_code}`
    };
    return axios.post(API_URL.addProperty, body);
};

export const registerPropertyHelper = (
    data: RegisterPropertyPayload,
    userName: string
) => {
    let body;
    if (data?.description) {
        body = {
            name: data.propertyName,
            smartbox_id: data?.smartBoxId || undefined,
            description: data.description,
            modified_by: userName,
            modified_at: Date.now(),
            zip_code: data?.zip_code
        };
    } else {
        body = {
            name: data.propertyName,
            smartbox_id: data?.smartBoxId || undefined,
            modified_by: userName,
            modified_at: Date.now(),
            zip_code: data?.zip_code
        };
    }

    return axios.post(API_URL.registerProperty + `/${data.propertyId}`, body);
};

export const fetchAllProperties = () => {
    return axios.get(API_URL.createProperty);
};

export const getPropertyTypes = () => {
    return axios.get(API_URL.getPropertyTypes);
};

export function fetchPropertyDetails(data: ActionPayload<string>) {
    console.log('API_URL.smartBoxDetails  ', API_URL.propertyDetails);
    return axios.get(API_URL.propertyDetails + `/${data.payload}`);
}

export function deleteProperty(propertyId: string) {
    return axios.delete(API_URL.deleteProperty + `/${propertyId}`);
}

export function propertyGroupDelete(propertyGroupId: string) {
    return axios.delete(API_URL.deletePropertyGroup + `/${propertyGroupId}`);
}

export function moveProperty(data: ActionPayload<MovePropertyPayload>) {
    return axios.post(
        API_URL.moveProperty + data.payload.propertyId + '/changeGroup',
        {
            new_property_group_id: data.payload.propertyGroupId
        }
    );
}

export function duplicatePropertyHelper(
    data: ActionPayload<DuplicatePropertyPayload>
) {
    return axios.post(API_URL.duplicateProperty, {
        description: data.payload.propertyDescription,
        name: data.payload.propertyName,
        property_group_id: data.payload.propertyGroupId,
        source_property_id: data.payload.propertySourceId,
        type: data.payload.propertySourceId,
        zip_code: data.payload.zipCode
    });
}
