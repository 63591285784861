import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button, {
    ButtonVariation,
    ButtonSize
} from '../../../../components/button';
import { CloseIconComponent } from '../../../../components/closeIconComponent';
import CMPDialog, {
    CMPDialogActions,
    CMPDialogContent,
    CMPDialogTitle
} from '../../../../components/cmpDialog';
import { useUrlParams } from '../../../../hooks/useParam';
import cmpTexts from '../../../../locale/en';
import { deleteZone } from '../../../../redux/zones/action';
import { pxToRem } from '../../../../utils/stylesUtils';

const StyledTypography = styled(Typography)(() => ({
    fontSize: pxToRem(18),
    lineHeight: pxToRem(24)
}));

const DeleteZoneOrEventModal = (props: {
    setIsModalOpen: (isAddCommentModalOpen: boolean) => void;
    description: string;
    heading: string;
    zoneId: string;
}) => {
    const { setIsModalOpen, description, heading, zoneId } = props;

    const { property } = useUrlParams();
    const dispatch = useDispatch();

    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        <CMPDialog onClose={handleClose}>
            <CMPDialogTitle sx={{ fontSize: pxToRem(28), fontWeight: 800 }}>
                <Typography
                    id="modal-modal-title"
                    fontSize={pxToRem(28)}
                    fontWeight="800"
                >
                    {heading}
                </Typography>
                <CloseIconComponent onClick={handleClose} />
            </CMPDialogTitle>
            <CMPDialogContent>
                <StyledTypography mb={pxToRem(20)}>
                    {description}
                </StyledTypography>
                <StyledTypography>
                    {cmpTexts.zones.deleteSubheading}
                </StyledTypography>
            </CMPDialogContent>
            <CMPDialogActions
                sx={{ justifyContent: 'center', gap: pxToRem(16) }}
            >
                <Button
                    buttonVariant={ButtonVariation.TEXT}
                    buttonSize={ButtonSize.MEDIUM}
                    sx={{ border: '2px solid #000010' }}
                    onClick={() => setIsModalOpen(false)}
                >
                    No, Cancel
                </Button>
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    buttonSize={ButtonSize.MEDIUM}
                    onClick={() => {
                        dispatch(deleteZone({ propertyId: property, zoneId }));
                        setIsModalOpen(false);
                    }}
                >
                    Yes, Delete
                </Button>
            </CMPDialogActions>
        </CMPDialog>
    );
};

export default DeleteZoneOrEventModal;
