import React, { Suspense } from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route
} from 'react-router-dom';
import FullScreenCircularLoader from '../components/fullScreenCircularLoader';
import {
    selectGeneralSectionAccessible,
    selectOrganizationModuleAccessible,
    selectPropertiesModuleAccessible,
    selectTemplatesModuleAccessible,
    selectUserModuleAccessible,
    selectViewAssetsAllowed,
    selectActivityMonitoringAccessible
} from '../redux/userOperations/reducer';
import Assets from '../screens/assets/assets';
import ConfigureElements from '../screens/elements/configureElements';
import Elements from '../screens/elements/elements';
import AccessDeniedPage from '../screens/errorBoundary/accessDeniedPage';
import ErrorBoundary from '../screens/errorBoundary/errorBoundary';
import Page404 from '../screens/errorBoundary/page404';
import ChangePassword from '../screens/login/changePassword';
import EmailVerification from '../screens/login/emailVerification';
import EmailVerificationSubmit from '../screens/login/emailVerificationSubmit';
import ForgotPassword from '../screens/login/fogotPassword';
import Login from '../screens/login/login';
import Evolve2 from '../screens/property/individualProperty/evolve2';
import IndividualProperty from '../screens/property/individualProperty/individualProperty';
import { PrivateRoute } from './privateRoute';
import PropertyRoutes from './propertyRoutes';
import PropertyConfiguration from '../screens/property/individualProperty/propertyConfiguration';

const Properties = React.lazy(() => import('../screens/property/properties'));
const Users = React.lazy(() => import('../screens/users/users'));
const Organizations = React.lazy(
    () => import('../screens/organizations/organizations')
);
const ActivityMonitoring = React.lazy(
    () => import('../screens/activityMonitoring/activityMonitoring')
);

const GeneralSection = React.lazy(
    () => import('../screens/generalSection/generalSection')
);

const Canvas = React.lazy(() => import('../screens/canvas/canvas'));

const CMPRouter = createBrowserRouter(
    createRoutesFromElements(
        <Route errorElement={<ErrorBoundary />}>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />
            <Route path="/changePassword" element={<ChangePassword />} />
            <Route
                path="/emailVerification/auth"
                element={<EmailVerification />}
            />
            <Route
                path="/emailVerified"
                element={<EmailVerificationSubmit />}
            />

            {/* Users Module */}
            <Route
                path="/users"
                element={
                    <PrivateRoute isAllowed={selectUserModuleAccessible}>
                        <Suspense fallback={<FullScreenCircularLoader />}>
                            <Users />
                        </Suspense>
                    </PrivateRoute>
                }
            />

            {/* Organization Module */}
            <Route
                path="/organizations"
                element={
                    <PrivateRoute
                        isAllowed={selectOrganizationModuleAccessible}
                    >
                        <Suspense fallback={<FullScreenCircularLoader />}>
                            <Organizations />
                        </Suspense>
                    </PrivateRoute>
                }
            />
            <Route
                path="/generalSection"
                element={
                    <PrivateRoute isAllowed={selectGeneralSectionAccessible}>
                        <Suspense fallback={<FullScreenCircularLoader />}>
                            <GeneralSection />
                        </Suspense>
                    </PrivateRoute>
                }
            />
            <Route
                path="/activity"
                element={
                    <PrivateRoute
                        isAllowed={selectActivityMonitoringAccessible}
                    >
                        <Suspense fallback={<FullScreenCircularLoader />}>
                            <ActivityMonitoring />
                        </Suspense>
                    </PrivateRoute>
                }
            />

            {/* Properties Module */}
            <Route
                path="/properties"
                element={
                    <PrivateRoute isAllowed={selectPropertiesModuleAccessible}>
                        <Suspense fallback={<FullScreenCircularLoader />}>
                            <Properties />
                        </Suspense>
                    </PrivateRoute>
                }
            />
            <Route
                path="/properties/:property"
                element={
                    <PropertyRoutes
                        isAllowed={selectPropertiesModuleAccessible}
                    >
                        <IndividualProperty />
                    </PropertyRoutes>
                }
            />
            <Route
                path="/properties/:property/property-config"
                element={
                    <PropertyRoutes
                        isAllowed={selectPropertiesModuleAccessible}
                    >
                        <PropertyConfiguration />
                    </PropertyRoutes>
                }
            />
            <Route
                path="/properties/:property/elements"
                element={
                    <PropertyRoutes isAllowed={selectTemplatesModuleAccessible}>
                        <Elements />
                    </PropertyRoutes>
                }
            />
            <Route
                path="/properties/:property/evolve2"
                element={
                    <PropertyRoutes isAllowed={selectTemplatesModuleAccessible}>
                        <Evolve2 />
                    </PropertyRoutes>
                }
            />
            <Route
                path="/properties/:property/elements/:elementId"
                element={
                    <PropertyRoutes
                        isAllowed={selectTemplatesModuleAccessible}
                        isTabPresent={false}
                    >
                        <ConfigureElements />
                    </PropertyRoutes>
                }
            />
            <Route
                path="/properties/:property/assets"
                element={
                    <PropertyRoutes isAllowed={selectViewAssetsAllowed}>
                        <Assets />
                    </PropertyRoutes>
                }
            />

            <Route
                path="/properties/:property/elements/:elementId/canvas"
                element={
                    <PropertyRoutes
                        isAllowed={selectTemplatesModuleAccessible}
                        isTabPresent={false}
                        hideBreadcrumbs
                        hideNavBar
                    >
                        <Suspense fallback={<FullScreenCircularLoader />}>
                            <Canvas />
                        </Suspense>
                    </PropertyRoutes>
                }
            />

            <Route path="/accessDenied" element={<AccessDeniedPage />} />
            <Route path="*" element={<Page404 />} />
        </Route>
    )
);

export default CMPRouter;
