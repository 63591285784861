import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    SelectChangeEvent
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonSize, ButtonVariation } from '../../components/button';
import CMPDialog, {
    CMPDialogActions,
    CMPDialogContent,
    CMPDialogTitle
} from '../../components/cmpDialog';
import Select from '../../components/select';
import { useUrlParams } from '../../hooks/useParam';
import cmpTexts from '../../locale/en';
import { pxToRem } from '../../utils/stylesUtils';
import { AddWidgetModalProps } from './elementsInterface';

const DescriptionText = styled('div')(() => ({
    fontSize: '0.98rem',
    fontWeight: 500
}));

const WidgetDescriptionText = styled('div')(() => ({
    fontSize: '0.87rem'
}));

const ContainerGrid = styled(Grid)(() => ({
    marginTop: pxToRem(16)
}));

const AddWidgetModal = (props: AddWidgetModalProps) => {
    const { setShowModal, selectedElementCategory, widgetTypeList } = props;

    const [widgetType, setWidgetType] = React.useState<any>();
    const navigate = useNavigate();
    const { property } = useUrlParams();

    const handleClose = () => {
        console.log('Add widget modal closed');
        setShowModal(false);
    };

    const handleSubmit = () => {
        navigate(`/properties/${property}/elements/create`, {
            state: {
                widget: { widgetType, type: selectedElementCategory },
                origin: 'element'
            }
        });
    };

    const handleChange = (e: SelectChangeEvent<unknown>) => {
        console.log('selected widget type - ', e.target.value);
        setWidgetType(e.target.value);
    };

    return (
        <div>
            <CMPDialog maxWidth="sm" onClose={handleClose}>
                <CMPDialogTitle sx={{ fontSize: pxToRem(24) }}>
                    {cmpTexts.addWidgetModal.modalHeading +
                        ' ' +
                        selectedElementCategory}
                </CMPDialogTitle>

                <CMPDialogContent>
                    <DescriptionText>
                        {`Choose a type of ${selectedElementCategory.toLowerCase()} to get started.`}
                    </DescriptionText>

                    <ContainerGrid container>
                        <Grid item xs={7}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    {selectedElementCategory}
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={widgetType}
                                    label={selectedElementCategory}
                                    onChange={handleChange}
                                >
                                    {widgetTypeList.map((wt: any) => {
                                        return (
                                            <MenuItem key={wt.id} value={wt}>
                                                {wt.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </ContainerGrid>

                    <WidgetDescriptionText>
                        {widgetType?.short_description}
                    </WidgetDescriptionText>
                </CMPDialogContent>

                <CMPDialogActions>
                    <Button
                        buttonVariant={ButtonVariation.TEXT}
                        buttonSize={ButtonSize.LARGE}
                        onClick={handleClose}
                    >
                        {cmpTexts.addWidgetModal.cancelButton}
                    </Button>
                    <Button
                        buttonVariant={ButtonVariation.CONTAINED}
                        buttonSize={ButtonSize.LARGE}
                        onClick={() => handleSubmit()}
                        disabled={!widgetType}
                    >
                        {cmpTexts.addWidgetModal.submit}
                    </Button>
                </CMPDialogActions>
            </CMPDialog>
        </div>
    );
};

export default AddWidgetModal;
