import { ApiResponseState, LoadingStatus } from '../common/state';
import { WidgetSkeleton } from '../element/model';
import {
    ElementListResponse,
    ElementLiveDetails,
    ElementsResponse
} from './model';

export interface ElementsListState {
    elements: ElementsResponse[];
    status: LoadingStatus;
    widgetTypeListLoadingStatus: LoadingStatus;
    allWidgetTypes: WidgetSkeleton[];
    error: string;
    pageList: ElementListResponse[];
    deleteElementStatus: ApiResponseState | undefined;
    openDeleteElementModal: boolean | undefined;
    elementLiveDetails: ElementLiveDetails | undefined;
}

export const initialElementsListState: ElementsListState = {
    elements: [],
    status: LoadingStatus.NONE,
    widgetTypeListLoadingStatus: LoadingStatus.NONE,
    allWidgetTypes: [],
    error: '',
    pageList: [],
    deleteElementStatus: undefined,
    elementLiveDetails: undefined,
    openDeleteElementModal: undefined
};
