import { Help } from '@mui/icons-material';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import { first, isArray, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Button, { ButtonSize, ButtonVariation } from '../../components/button';
import CMPDialog, {
    CMPDialogActions,
    CMPDialogTitle
} from '../../components/cmpDialog';
import FileDropZone, {
    DEFAULT_ALLOWED_IMAGE_AND_VIDEO_FILE_TYPES,
    DEFAULT_ASSET_UPLOAD_INFO_MESSAGE_IMAGE_AND_VIDEO
} from '../../components/fileDropZone';
import { useSnackbar } from '../../components/snackbar';
import Tooltip from '../../components/tooltip';
import { useUrlParams } from '../../hooks/useParam';
import cmpTexts from '../../locale/en';
import {
    assetOperationLoaderReset,
    createAssets
} from '../../redux/assets/action';
import { AssetStatus } from '../../redux/assets/actionPayload';
import { Asset, OwnerType } from '../../redux/assets/model';
import {
    existingAssetsNames,
    selectAssetOperationLoadingState,
    selectAssets
} from '../../redux/assets/reducer';
import { LoadingStatus } from '../../redux/common/state';
import { generateUUID } from '../../utils/stringUtils';
import { pxToRem } from '../../utils/stylesUtils';
import PreviewAssets from './previewAssets';

const FileDropAndPreviewArea = styled(Box)(() => ({
    marginBottom: pxToRem(24),
    display: 'flex',
    height: pxToRem(270),
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
}));

export const MULTI_UPLOAD_FILE_LIMIT = 20;

export interface AssetDetails {
    id: string;
    file: File;
    status: AssetStatus;
    name: string;
    uploadPercent: number;
    type: string;
}

interface UploadAssetProps {
    allowedFileTypes?: string[];
    assetUploadInfoMessage?: string;
    mode?: 'dark' | 'light';
    setIsModalOpen: (show: boolean) => void;
    onAssetUploaded?: (asset: Asset) => void;
    filesLimit?: number;
    maxFileSize?: number;
}

const UploadAssetModal = (props: UploadAssetProps) => {
    const {
        mode = 'light',
        setIsModalOpen,
        onAssetUploaded,
        allowedFileTypes = DEFAULT_ALLOWED_IMAGE_AND_VIDEO_FILE_TYPES,
        assetUploadInfoMessage = DEFAULT_ASSET_UPLOAD_INFO_MESSAGE_IMAGE_AND_VIDEO,
        maxFileSize,
        filesLimit = 1
    } = props;

    const { showSnackbar } = useSnackbar();
    const isDarkMode = mode === 'dark';
    const dispatch = useDispatch();
    const [isMultiFileUpload, setIsMultiFileUpload] = useState(false);
    const { property } = useUrlParams();
    const existingRemoteAssetNames = useSelector(existingAssetsNames);
    const uploadLoader = useSelector(selectAssetOperationLoadingState);

    const lastUploadedAsset = first(useSelector(selectAssets));
    const [cancelUploadController, setCancelUploadController] = useState<
        AbortController | undefined
    >(undefined);

    const assetUploadInProgress = uploadLoader === LoadingStatus.INITIATED;

    const formik = useFormik<AssetDetails[]>({
        initialValues: [],
        validationSchema: Yup.array().of(
            Yup.object()
                .shape({
                    name: Yup.string()
                        .trim()
                        .required('*Required')
                        .min(3, 'Should have atleast 3 characters')
                })
                .test(
                    'invalidTypes',
                    cmpTexts.assets.invalidAssetError,
                    (data, ctx) => {
                        if (!allowedFileTypes.includes(data?.type))
                            return ctx.createError({
                                path: `${ctx.path}.name`,
                                message: cmpTexts.assets.invalidAssetError
                            });
                        return true;
                    }
                )
                .test(
                    'isNameUnique',
                    cmpTexts.assets.nameAlreadyExists,
                    (data, ctx) => {
                        const existingLocalNames = isArray(ctx.parent)
                            ? ctx.parent
                                  .filter((a) => a.id !== data?.id)
                                  .map((a) => a.name)
                            : [];

                        const existingAssetNames = [
                            ...existingRemoteAssetNames,
                            ...existingLocalNames
                        ];

                        if (existingAssetNames.includes(data.name))
                            return ctx.createError({
                                path: `${ctx.path}.name`,
                                message: cmpTexts.assets.nameAlreadyExists
                            });
                        return true;
                    }
                )
        ),
        onSubmit: () => {}
    });

    useEffect(() => {
        if (assetUploadInProgress) return;

        const nonUploadedAssets = formik.values.filter(
            (a) => a.status !== AssetStatus.SUCCESS
        );

        if (isEmpty(nonUploadedAssets) && formik.dirty) setIsModalOpen(false);
        else formik.setValues(nonUploadedAssets);
    }, [assetUploadInProgress, formik.dirty]);

    useEffect(() => {
        if (uploadLoader === LoadingStatus.DONE && lastUploadedAsset) {
            dispatch(assetOperationLoaderReset());
            onAssetUploaded?.(lastUploadedAsset);
        }
    }, [uploadLoader, lastUploadedAsset]);

    const handleUpload = () => {
        const assets = formik.values;

        if (isEmpty(assets)) return;

        const abortController = new AbortController();
        setCancelUploadController(abortController);

        const assetFilesObj = assets.map((asset, index) => {
            formik.setFieldValue(`[${index}].status`, AssetStatus.UPLOADING);
            formik.setFieldValue(`[${index}].uploadPercent`, 0);
            return {
                file: new File([asset.file], `${asset.name}`, {
                    type: asset.type
                }),
                id: asset.id
            };
        });

        dispatch(
            createAssets({
                files: assetFilesObj,
                owner_type: OwnerType.PROPERTY,
                owner_id: property,
                onUploadProgress: (percent, id, status) => {
                    const assets = formik.values;
                    const updatedAssets = assets.map((a) => {
                        if (a.id === id) {
                            a.uploadPercent = percent;
                            a.status = status as AssetStatus;
                        }
                        return a;
                    });
                    formik.setValues(updatedAssets);
                },
                cancelUploadController: abortController
            })
        );
    };

    const handleClose = (_?: any, reason?: any) => {
        if (reason === 'backdropClick' && assetUploadInProgress) return;

        setIsModalOpen(false);
        formik.handleReset('e');
    };

    const handleCancel = () => {
        if (!assetUploadInProgress) handleClose();

        const assets = formik.values;
        const nonUploadedAssets = assets
            .filter((a) => a.status !== AssetStatus.SUCCESS)
            .map((a) => ({
                ...a,
                uploadPercent: 0,
                status: AssetStatus.READY
            }));

        formik.setValues(nonUploadedAssets);

        cancelUploadController?.abort();
    };

    const onFileChange = (files: File[]) => {
        if (isEmpty(files)) return;
        setIsMultiFileUpload(files.length > 1);

        const selectedAssets: AssetDetails[] = files.map((f) => ({
            id: generateUUID(),
            file: f,
            status: AssetStatus.READY,
            name: f.name.split('.').slice(0, -1).join('.'),
            uploadPercent: 0,
            type: f.type
        }));

        formik.setValues(selectedAssets);
    };

    const onAssetNameChange = (assetId: string, name: string) => {
        const assetIndex = formik.values.findIndex(
            (a: AssetDetails) => a.id === assetId
        );
        if (assetIndex !== -1)
            formik.setFieldValue(`[${assetIndex}].name`, name);
    };

    const onDeleteAsset = (assetId: string) => {
        const updatedAssets = formik.values.filter((a) => a.id !== assetId);
        formik.setValues(updatedAssets);
    };

    const getAssetError = (assetId: string): string => {
        const assetIndex = formik.values.findIndex(
            (a: AssetDetails) => a.id === assetId
        );
        if (assetIndex !== -1 && formik.errors[assetIndex])
            return formik.errors[assetIndex]?.name ?? '';
        return '';
    };

    return (
        <CMPDialog
            onClose={handleClose}
            disableEscapeKeyDown={assetUploadInProgress}
            disableClose={assetUploadInProgress}
            maxWidth="sm"
            mode={mode}
            PaperProps={{
                style: {
                    background: isDarkMode ? '#171725' : '#fff',
                    boxSizing: 'content-box'
                }
            }}
        >
            <CMPDialogTitle
                sx={{
                    fontSize: pxToRem(28),
                    fontWeight: 800,
                    color: isDarkMode ? '#fff' : '#000010'
                }}
            >
                {cmpTexts.assets.modalHeading}
                <Tooltip message={cmpTexts.assets.uploadAssetInfoMessage}>
                    <Help
                        sx={{
                            cursor: 'pointer',
                            marginLeft: pxToRem(10),
                            pointerEvents: 'auto'
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                    />
                </Tooltip>
            </CMPDialogTitle>
            <FileDropAndPreviewArea
                sx={{
                    width:
                        isMultiFileUpload && formik.values.length > 0
                            ? pxToRem(502)
                            : pxToRem(336),
                    height:
                        formik.values.length > 3 ? pxToRem(456) : pxToRem(320)
                }}
            >
                {formik.values.length > 0 ? (
                    <PreviewAssets
                        assets={formik.values}
                        assetUploadInProgress={assetUploadInProgress}
                        isMultiFileUpload={isMultiFileUpload}
                        isDarkMode={false}
                        onDeleteAssetClick={onDeleteAsset}
                        onChangeAssetName={onAssetNameChange}
                        getAssetError={getAssetError}
                        allowedFileTypes={allowedFileTypes}
                    />
                ) : (
                    <FileDropZone
                        maxFileSize={maxFileSize}
                        filesLimit={filesLimit}
                        isDarkMode={isDarkMode}
                        onFileChange={onFileChange}
                        onDropRejected={(message) =>
                            showSnackbar(message, 'error', 5000)
                        }
                        allowedFileTypes={allowedFileTypes}
                        fileUploadInfoMessage={assetUploadInfoMessage}
                    />
                )}
            </FileDropAndPreviewArea>
            <CMPDialogActions
                sx={{ gap: pxToRem(16), justifyContent: 'center' }}
            >
                <Button
                    buttonVariant={ButtonVariation.OUTLINED}
                    buttonSize={ButtonSize.MEDIUM}
                    onClick={handleCancel}
                    sx={
                        !isDarkMode
                            ? {
                                  color: '#000010',
                                  borderColor: '#000010'
                              }
                            : {}
                    }
                >
                    {cmpTexts.assets.cancel}
                </Button>
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    buttonSize={ButtonSize.MEDIUM}
                    disabled={
                        assetUploadInProgress ||
                        !formik.isValid ||
                        isEmpty(formik.values)
                    }
                    onClick={handleUpload}
                >
                    {cmpTexts.assets.upload}
                </Button>
            </CMPDialogActions>
        </CMPDialog>
    );
};

export default UploadAssetModal;
