import AddIcon from '@mui/icons-material/Add';
import {
    FormControl,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    SelectChangeEvent,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { isArray } from 'lodash';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Button, { ButtonSize, ButtonVariation } from '../../components/button';
import CMPDialog, {
    CMPDialogActions,
    CMPDialogContent,
    CMPDialogTitle
} from '../../components/cmpDialog';
import Select from '../../components/select';
import { useUrlParams } from '../../hooks/useParam';
import cmpTexts from '../../locale/en';
import {
    ContentField,
    FieldType,
    WidgetSkeleton
} from '../../redux/element/model';
import { ElementCategory, ElementsData } from '../../redux/elementsList/model';
import { pxToRem } from '../../utils/stylesUtils';
import { AddElementProps } from './elementsInterface';

function AddElement(props: AddElementProps) {
    const {
        setShowModal,
        addElementCategory,
        elements,
        formik,
        setIsEdited,
        isEdited,
        widgetTypeList
    } = props;
    const [widgetType, setWidgetType] = React.useState<WidgetSkeleton>();
    const [showWidgetTypeDropDown, setShowWidgetTypeDropDown] = useState(true);
    const [showWidgetDropDown, setWidgetDropDown] = useState(false);

    const MultiwidgetIndex = formik.values.content_fields.findIndex(
        (element: ContentField) => element.type === FieldType.MultiWidgetType
    );
    const [title, setTitle] = useState(
        `Select ${addElementCategory.toLowerCase()} type`
    );
    const [selectedElement, setSelectedElement] = useState<string>('');
    const [buttonText, setButtonText] = useState('NEXT');
    const navigate = useNavigate();
    const { property } = useUrlParams();
    const location = useLocation();
    const TitleCase = styled(Typography)(() => ({
        textTransform: 'capitalize'
    }));

    const handleChange = (e: SelectChangeEvent<unknown>) => {
        setWidgetType(e.target.value as WidgetSkeleton);
    };

    // this is a patchy code written as doing it the write way would require bigger refactor,
    // this will be implemented property once we start working on cavas design
    // Here we are finding the index of ** MultiWidgetType content field **
    const mwtIndex = formik.values.content_fields.findIndex(
        (cf: ContentField) => cf.type === FieldType.MultiWidgetType
    );

    const handleSubmit = () => {
        if (showWidgetTypeDropDown) {
            setShowWidgetTypeDropDown(false);
            setWidgetDropDown(true);
            setTitle(`Select ${addElementCategory.toLowerCase()}`);
            setButtonText('SAVE');
        } else {
            if (mwtIndex === -1) {
                return;
            }

            if (
                isArray<string>(formik.values.content_fields[mwtIndex].values)
            ) {
                const newValues = formik.values.content_fields[mwtIndex]
                    .values as string[];
                newValues.push(selectedElement);
                formik.setFieldValue(
                    `content_fields[${mwtIndex}].values`,
                    newValues
                );
                formik.setFieldTouched(
                    `content_fields[${mwtIndex}].values`,
                    true
                );
                setIsEdited(true);
                setShowModal(false);
            } else {
                if (formik.values.content_fields[mwtIndex].values) {
                    const newValues = Array(
                        formik.values.content_fields[mwtIndex].values
                    );
                    newValues.push(selectedElement);
                    formik.setFieldValue(
                        `content_fields[${mwtIndex}].values`,
                        newValues
                    );
                    setIsEdited(true);
                    setShowModal(false);
                } else {
                    const newValues = [];
                    newValues.push(selectedElement);
                    formik.setFieldValue(
                        `content_fields[${mwtIndex}].values`,
                        newValues
                    );
                    setIsEdited(true);
                    setShowModal(false);
                }
            }
        }
    };

    const handleWarningModal = () => {
        if (
            location?.pathname.split('/').pop() === 'create' &&
            ((isArray(formik.values.content_fields[mwtIndex].values) &&
                (formik.values.content_fields[mwtIndex].values as string[])
                    .length === 0) ||
                !formik.values.content_fields[mwtIndex].values)
        ) {
            handleCreateNewElement();
        } else if (isEdited) {
            navigate(`/properties/${property}/elements/create`, {
                state: {
                    widget: { widgetType, type: addElementCategory },
                    origin:
                        addElementCategory === ElementCategory.RAIL
                            ? ElementCategory.PAGE
                            : ElementCategory.RAIL
                }
            });
        } else {
            handleCreateNewElement();
        }
    };

    const handleCreateNewElement = () => {
        console.log();
        navigate(`/properties/${property}/elements/create`, {
            state: {
                widget: { widgetType, type: addElementCategory },
                origin:
                    addElementCategory === ElementCategory.RAIL
                        ? ElementCategory.PAGE
                        : ElementCategory.RAIL
            }
        });
    };

    return (
        <CMPDialog maxWidth="sm" onClose={() => setShowModal(false)}>
            <CMPDialogTitle sx={{ fontSize: pxToRem(24) }}>
                {title}
            </CMPDialogTitle>

            <CMPDialogContent>
                {showWidgetTypeDropDown && (
                    <Grid container sx={{ paddingTop: pxToRem(10) }}>
                        <Grid item xs={7}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    <TitleCase>
                                        {addElementCategory.toLowerCase() +
                                            ' type'}
                                    </TitleCase>
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={widgetType}
                                    label={
                                        addElementCategory.toLowerCase() +
                                        ' type'
                                    }
                                    onChange={handleChange}
                                >
                                    {widgetTypeList.map((wt: any) => {
                                        if (
                                            formik.values.content_fields &&
                                            MultiwidgetIndex > -1 &&
                                            formik.values.content_fields[
                                                MultiwidgetIndex
                                            ]?.allowed_type_id?.indexOf(wt.id) >
                                                -1
                                        )
                                            return (
                                                <MenuItem
                                                    key={wt.id}
                                                    value={wt}
                                                >
                                                    {wt.name}
                                                </MenuItem>
                                            );
                                        return null;
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                )}

                {showWidgetDropDown && widgetType && (
                    <Grid container sx={{ paddingTop: pxToRem(10) }}>
                        <Grid item xs={7}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    <TitleCase>
                                        {addElementCategory.toLowerCase()}
                                    </TitleCase>
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedElement}
                                    label={addElementCategory.toLowerCase()}
                                    onChange={(e: SelectChangeEvent<unknown>) =>
                                        setSelectedElement(
                                            e.target.value as string
                                        )
                                    }
                                >
                                    {widgetType.access_details?.Add && (
                                        <MenuItem>
                                            <Link
                                                underline="none"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    verticalAlign: 'middle',
                                                    width: '100%'
                                                }}
                                                onClick={handleWarningModal}
                                            >
                                                <AddIcon
                                                    sx={{
                                                        verticalAlign: 'middle'
                                                    }}
                                                />
                                                {addElementCategory ===
                                                ElementCategory.RAIL
                                                    ? 'Create New RAIL'
                                                    : 'Create New Widget'}
                                            </Link>
                                        </MenuItem>
                                    )}
                                    {elements.map((element: ElementsData) => {
                                        if (
                                            element.widget_type_id ===
                                                widgetType.id &&
                                            String(
                                                formik.values.content_fields[
                                                    mwtIndex
                                                ].values
                                            )
                                                .split(',')
                                                .indexOf(element.id) === -1
                                        ) {
                                            return (
                                                <MenuItem
                                                    key={element.id}
                                                    value={element.id}
                                                >
                                                    {element.name}
                                                </MenuItem>
                                            );
                                        }
                                        return null;
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
            </CMPDialogContent>

            <CMPDialogActions>
                <Button
                    buttonVariant={ButtonVariation.TEXT}
                    buttonSize={ButtonSize.LARGE}
                    onClick={() => setShowModal(false)}
                >
                    {cmpTexts.addWidgetModal.cancelButton}
                </Button>
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    buttonSize={ButtonSize.LARGE}
                    onClick={handleSubmit}
                    disabled={
                        !(
                            (widgetType && showWidgetTypeDropDown) ||
                            (selectedElement && showWidgetDropDown)
                        )
                    }
                >
                    {buttonText}
                </Button>
            </CMPDialogActions>
        </CMPDialog>
    );
}

export default AddElement;
