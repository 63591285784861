import React from 'react';
import Button, { ButtonSize, ButtonVariation } from './button';
import CMPDialog, {
    CMPDialogActions,
    CMPDialogContent,
    CMPDialogTitle
} from './cmpDialog';

interface Props {
    onClose: () => void;
    title?: string;
    description?: string;
    onPositiveButtonClick?: () => void;
    positiveButtonText?: string;
    negativeButtonText?: string;
}

const ConfirmationModal = (props: Props) => {
    const {
        title,
        description,
        onClose,
        positiveButtonText = 'Yes',
        negativeButtonText = 'No',
        onPositiveButtonClick: yesClick
    } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <CMPDialog maxWidth="sm" onClose={handleClose}>
            <CMPDialogTitle>{title}</CMPDialogTitle>

            <CMPDialogContent>{description}</CMPDialogContent>

            <CMPDialogActions>
                <Button
                    buttonVariant={ButtonVariation.TEXT}
                    onClick={handleClose}
                    buttonSize={ButtonSize.LARGE}
                    sx={{ fontWeight: 600 }}
                >
                    {negativeButtonText}
                </Button>
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    buttonSize={ButtonSize.LARGE}
                    onClick={yesClick}
                >
                    {positiveButtonText}
                </Button>
            </CMPDialogActions>
        </CMPDialog>
    );
};

export default ConfirmationModal;
