import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { capitalize, isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ThreeDotIcon } from '../../assets/svg/threeDotsHoriz.svg';
import { ReactComponent as Trashcan } from '../../assets/svg/trashBin.svg';
import IconStylizer from '../../components/iconStylizer';
import { useUrlParams } from '../../hooks/useParam';
import { AccessDetails } from '../../redux/element/model';
import { checkIfElementIsLive } from '../../redux/elementsList/action';
import { ElementCategory, ElementsData } from '../../redux/elementsList/model';
import { selectElementDeleteAllowed } from '../../redux/userOperations/reducer';
import { pxToRem } from '../../utils/stylesUtils';

const ElementActions = (props: {
    accessDetails: AccessDetails | undefined;
    element: ElementsData;
    handleOpenModal: (id: string, type: string) => void;
}) => {
    const { accessDetails, element, handleOpenModal } = props;
    const dispatch = useDispatch();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleActionClose = () => setIsMenuOpen(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { property } = useUrlParams();
    const isDeleteElementAllowed = useSelector(selectElementDeleteAllowed);
    const handleAction = () => {
        setIsMenuOpen(true);
    };

    function handleDelete(data: ElementsData) {
        handleActionClose();
        // TODO: Need to remove.
        dispatch(checkIfElementIsLive(data.id, data.type, property));
        handleOpenModal(data.id, data.type);
    }

    const getElementActionMenus = (): React.ReactElement[] => {
        const menuOptions = [];

        if (isDeleteElementAllowed && accessDetails?.Delete)
            menuOptions.push(
                <MenuItem
                    onClick={() => {
                        handleDelete(element);
                    }}
                    sx={{ color: '#931621' }}
                >
                    <IconStylizer
                        height={pxToRem(24)}
                        width={pxToRem(24)}
                        color={'#931621'}
                        sx={{
                            marginRight: pxToRem(10),
                            display: 'flex'
                        }}
                    >
                        <Trashcan />
                    </IconStylizer>
                    {`Delete ${capitalize(
                        element.type === ElementCategory.WIDGET
                            ? 'Tool'
                            : element.type
                    )}`}
                </MenuItem>
            );

        return menuOptions;
    };

    const elementActionMenus = getElementActionMenus();

    return (
        <>
            {!isEmpty(elementActionMenus) && (
                <Box style={{ display: 'flex', flex: 1 }}>
                    <IconButton
                        ref={buttonRef}
                        id="actions-button"
                        aria-haspopup="true"
                        onClick={handleAction}
                    >
                        <IconStylizer
                            height={pxToRem(24)}
                            width={pxToRem(24)}
                            color="#000000"
                        >
                            <ThreeDotIcon />
                        </IconStylizer>
                    </IconButton>
                    <Menu
                        id={'action-menu'}
                        anchorEl={buttonRef.current}
                        open={Boolean(isMenuOpen)}
                        onClose={handleActionClose}
                        MenuListProps={{
                            'aria-labelledby': 'actions-button'
                        }}
                        sx={{
                            '&#action-menu .MuiPaper-rounded': {
                                boxShadow: 'none',
                                outline: `1px solid #000000`,
                                borderRadius: pxToRem(7)
                            }
                        }}
                    >
                        {elementActionMenus.map((elementAction, index) => (
                            <Box key={index}>{elementAction}</Box>
                        ))}
                    </Menu>
                </Box>
            )}
        </>
    );
};

export default ElementActions;
