import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    AccordionDetails,
    Box,
    FormHelperText,
    styled,
    Typography
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import React from 'react';
import cmpTexts from '../../../locale/en';
import { IndividualFaq } from '../../../redux/propertyValue/model';
import { pxToRem } from '../../../utils/stylesUtils';
import { CustomTextField } from '../individualProperty/propertyThemeAndAssetSection';

interface Props {
    id: number;
    expanded: boolean;
    faqErrors: boolean;
    accordionOnCLick: any;
    item: IndividualFaq;
    handleDelete: any;
    handleChange: any;
    handleBlur: any;
    errorQuestionText: string;
    errorAnswerText: string;
    dragHandle: React.ReactNode;
    viewOnly: boolean;
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1.5px solid #ffff`,
    '&:before': {
        display: 'none'
    }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(() => ({
    backgroundColor: '#000000',
    color: '#ffff',
    flexDirection: 'row-reverse',
    '&.disable-accordion-style': {
        backgroundColor: 'grey'
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        '&.Mui-expanded': {
            transform: 'rotate(90deg)'
        },
        color: '#ffff',
        position: 'absolute',
        left: 30
    },
    '& .MuiAccordionSummary-content': {
        paddingLeft: pxToRem(40)
    }
}));

const IndividualFAQComponent = (props: Props) => {
    const {
        id,
        expanded,
        faqErrors,
        accordionOnCLick,
        item,
        handleDelete,
        handleChange,
        handleBlur,
        errorQuestionText,
        errorAnswerText,
        dragHandle,
        viewOnly
    } = props;
    return (
        <>
            <Accordion
                key={`accordion-${id}`}
                expanded={expanded}
                sx={faqErrors ? { border: 'red solid 1px' } : {}}
            >
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id={`panel-header-${id}`}
                    onClick={accordionOnCLick(id)}
                    className={viewOnly ? 'disable-accordion-style' : ''}
                >
                    {!viewOnly && dragHandle}
                    <Typography sx={{ flex: 1 }} variant="subtitle1">
                        {item.question ? item.question : 'Question'}
                    </Typography>
                    {!viewOnly && <DeleteIcon onClick={handleDelete(id)} />}
                </AccordionSummary>
                <AccordionDetails id={`panel-details-${id}`}>
                    <Box key={`Question-${id}`}>
                        <Typography>Question</Typography>
                        <CustomTextField
                            fullWidth={true}
                            multiline={false}
                            rows={1}
                            variant="outlined"
                            value={item.question}
                            name={`faq[${id}].question`}
                            maxLength={200}
                            onChange={handleChange(id, 'question')}
                            placeholder={cmpTexts.faqSection.question}
                            onBlur={handleBlur}
                            helperText={errorQuestionText}
                            error={Boolean(faqErrors)}
                            disabled={viewOnly}
                        />
                    </Box>
                    <Box key={`Answer-${id}`}>
                        <Typography>Answer</Typography>
                        <CustomTextField
                            fullWidth={true}
                            multiline={true}
                            rows={4}
                            variant="outlined"
                            value={item.answer}
                            name={`faq[${id}].answer`}
                            maxLength={999}
                            onChange={handleChange(id, 'answer')}
                            onBlur={handleBlur}
                            placeholder={cmpTexts.faqSection.answer}
                            helperText={errorAnswerText}
                            error={faqErrors}
                            disabled={viewOnly}
                        />
                    </Box>
                </AccordionDetails>
            </Accordion>
            {faqErrors && (
                <FormHelperText error={faqErrors}>
                    {cmpTexts.faqSection.incomplete}
                </FormHelperText>
            )}
        </>
    );
};

export default IndividualFAQComponent;
