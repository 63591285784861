import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    styled,
    Breakpoint,
    PaperProps
} from '@mui/material';
import React from 'react';
import { pxToRem } from '../utils/stylesUtils';
import { CloseIconComponent } from './closeIconComponent';
import { DisableableBox } from './disableableBox';

export const CMPDialogTitle = styled(DialogTitle)(() => ({
    '&.MuiDialogTitle-root': {
        padding: 0,
        marginBottom: pxToRem(16)
    },
    fontSize: pxToRem(30)
}));

export const CMPDialogContent = styled(DialogContent)(() => ({
    '&.MuiDialogContent-root': {
        padding: 0,
        marginBottom: pxToRem(24)
    }
}));

export const CMPDialogActions = styled(DialogActions)(() => ({
    padding: 0
}));

interface CMPDialogProps {
    children: React.ReactNode;
    onClose: () => void;
    maxWidth?: Breakpoint;
    PaperProps?: Partial<PaperProps<React.ElementType>>;
    disableEscapeKeyDown?: boolean;
    disableClose?: boolean;
    mode?: 'dark' | 'light';
}

const CMPDialog = ({
    children,
    onClose,
    maxWidth = 'md',
    disableClose = false,
    mode = 'light',
    ...rest
}: CMPDialogProps) => {
    return (
        <Dialog
            fullWidth
            maxWidth={maxWidth}
            open={true}
            scroll="paper"
            onClose={onClose}
            sx={{
                '.MuiDialog-paper': {
                    borderRadius: pxToRem(15),
                    padding: pxToRem(32)
                }
            }}
            {...rest}
        >
            <DisableableBox disabled={disableClose}>
                <CloseIconComponent onClick={onClose} mode={mode} />
            </DisableableBox>
            {children}
        </Dialog>
    );
};

export default CMPDialog;
