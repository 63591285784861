import React from 'react';
import { Box } from '@mui/material';
import Button, { ButtonVariation, ButtonSize } from './button';
import { pxToRem } from '../utils/stylesUtils';
import CMPDialog, {
    CMPDialogActions,
    CMPDialogContent,
    CMPDialogTitle
} from './cmpDialog';

interface Props {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    handleDelete: () => void;
    handleSave: () => void;
}

const CancelClickModal = (props: Props) => {
    const { setShowModal, handleDelete, handleSave } = props;

    return (
        <CMPDialog maxWidth="sm" onClose={() => setShowModal(false)}>
            <CMPDialogTitle sx={{ fontSize: pxToRem(32) }}>
                {text.modalHeading}
            </CMPDialogTitle>
            <CMPDialogContent>
                <Box>{text.modalBody}</Box>
            </CMPDialogContent>
            <CMPDialogActions>
                <Button
                    buttonVariant={ButtonVariation.TEXT}
                    buttonSize={ButtonSize.LARGE}
                    onClick={handleDelete}
                    sx={{ fontWeight: 600 }}
                >
                    {text.button[0]}
                </Button>
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    onClick={() => handleSave()}
                >
                    {text.button[1]}
                </Button>
            </CMPDialogActions>
        </CMPDialog>
    );
};

export default CancelClickModal;

const text = {
    modalHeading: 'Save your changes? ',
    modalBody:
        'You have unsaved changes. Choose to save or delete your changes as you exit this page.',
    button: ['Discard', 'SAVE']
};
