import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Breadcrumbs, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useUrlParams } from '../hooks/useParam';
import { BreadCrumb } from '../redux/login/model';
import { selectBreadCrumb } from '../redux/login/reducer';
import {
    getPropertyDetails,
    purgeCurrentPropertyDetails
} from '../redux/propertylist/action';
import { selectCurrentPropertyDetails } from '../redux/propertylist/reducer';
import { coreRootState } from '../redux/store.types';
import {
    selectTemplatesModuleAccessible,
    selectViewAssetsAllowed,
    selectViewEvolve2Allowed,
    selectViewPropertyAllowed
} from '../redux/userOperations/reducer';
import { getLastModifiedString } from '../utils/propertiesUtil';
import { pxToRem, StylesDictionaryCssProps } from '../utils/stylesUtils';
import { PrivateRoute } from './privateRoute';
import { updateComments } from '../redux/propertyValue/action';

const Container = styled('div')(() => ({
    flex: 1,
    flexDirection: 'row',
    height: '100%'
}));

const StyledBoxPropertyManagement = styled(Box)(() => ({
    width: '90%',
    bgcolor: 'background.paper',
    borderBottom: 'solid',
    borderWidth: `${pxToRem(0.01)}`,
    borderBottomColor: '#D0D0D0',
    marginLeft: 45,
    marginTop: 6,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

const styles: StylesDictionaryCssProps = {
    propertyDetailsTab: {
        padding: 2,
        paddingBottom: 0,
        fontSize: '1rem',
        color: '#171725',
        fontWeight: 600,
        textTransform: 'capitalize'
    },
    elementLibraryTab: {
        padding: 2,
        marginLeft: 30,
        paddingBottom: 0,
        fontSize: '1rem',
        color: '#171725',
        fontWeight: 600,
        textTransform: 'capitalize'
    },
    breadcrumb: {
        paddingTop: pxToRem(16),
        marginBottom: 1,
        marginLeft: 6
    },
    link: {
        color: '#171726',
        fontSize: '0.95rem'
    },
    textLink: {
        color: '#171726',
        fontSize: '0.95rem'
    }
};

interface PropertyRoutesProps {
    isAllowed: (state: coreRootState) => boolean | undefined;
    children: React.ReactNode;
    isTabPresent?: boolean;
    hideBreadcrumbs?: boolean;
    hideNavBar?: boolean;
}
const getPath = (pathname: string[]) =>
    pathname.includes('elements')
        ? 'element'
        : pathname.includes('assets')
        ? 'asset'
        : pathname.includes('evolve2')
        ? 'evolve2'
        : pathname.includes('property-config')
        ? 'property-config'
        : 'property';

const PropertyRoutes = (props: PropertyRoutesProps) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { property } = useUrlParams();
    const breadCrumbs = useSelector(selectBreadCrumb);
    const {
        isAllowed,
        children,
        isTabPresent = true,
        hideBreadcrumbs = false,
        hideNavBar = false
    } = props;

    const [value, setValue] = useState(getPath(location.pathname.split('/')));
    const isElementAllowed = useSelector(selectTemplatesModuleAccessible);
    const isPropertyAllowed = useSelector(selectViewPropertyAllowed);
    const isAssetAllowed = useSelector(selectViewAssetsAllowed);
    const isEvolve2Allowed = useSelector(selectViewEvolve2Allowed);
    const currentSelectedProperty = useSelector(selectCurrentPropertyDetails);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        setValue(getPath(location.pathname.split('/')));
    }, [location]);

    useEffect(() => {
        dispatch(getPropertyDetails(property));
        return () => {
            dispatch(purgeCurrentPropertyDetails());
            dispatch(updateComments(''));
        };
    }, []);

    return (
        <PrivateRoute isAllowed={isAllowed} hideNavBar={hideNavBar}>
            <Container>
                {!hideBreadcrumbs && (
                    <Breadcrumbs
                        separator={<ChevronRightIcon fontSize="small" />}
                        sx={styles.breadcrumb}
                    >
                        {breadCrumbs.map((b: BreadCrumb, i: number) => {
                            if (i === breadCrumbs.length - 1) {
                                return (
                                    <div style={styles.textLink}>{b.name}</div>
                                );
                            } else {
                                return (
                                    <Link
                                        to={{
                                            pathname: b.link
                                        }}
                                        state={b.state}
                                        style={styles.link}
                                    >
                                        {b.name}
                                    </Link>
                                );
                            }
                        })}
                    </Breadcrumbs>
                )}

                {isTabPresent && (
                    <StyledBoxPropertyManagement>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                        >
                            {isPropertyAllowed && (
                                <Tab
                                    label="OnStream Design"
                                    value="property"
                                    component={Link}
                                    to={`/properties/${property}`}
                                    style={styles.propertyDetailsTab}
                                />
                            )}
                            {isPropertyAllowed && (
                                <Tab
                                    label="Property Config."
                                    value="property-config"
                                    component={Link}
                                    to={`/properties/${property}/property-config`}
                                    style={styles.elementLibraryTab}
                                />
                            )}
                            {isEvolve2Allowed && (
                                <Tab
                                    label="EVOLVE M"
                                    value="evolve2"
                                    component={Link}
                                    to={`/properties/${property}/evolve2`}
                                    style={styles.elementLibraryTab}
                                    key={property}
                                />
                            )}
                            {isElementAllowed && (
                                <Tab
                                    label="Element Library"
                                    value="element"
                                    component={Link}
                                    to={`/properties/${property}/elements`}
                                    style={styles.elementLibraryTab}
                                />
                            )}
                            {isAssetAllowed && (
                                <Tab
                                    label="Assets"
                                    value="asset"
                                    component={Link}
                                    to={`/properties/${property}/assets`}
                                    style={styles.elementLibraryTab}
                                />
                            )}
                        </Tabs>
                        {currentSelectedProperty.id && (
                            <Typography fontSize={pxToRem(14)} fontWeight={600}>
                                {getLastModifiedString(
                                    currentSelectedProperty.modified_by,
                                    currentSelectedProperty.modified_at
                                )}
                            </Typography>
                        )}
                    </StyledBoxPropertyManagement>
                )}
                {children}
            </Container>
        </PrivateRoute>
    );
};

export default PropertyRoutes;
